import { useMemo } from "react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { useRole } from "./useRole";

export function useCanViewMyWorkTimes() {
    const { appCompany } = useAppCompanyContext();
    const { role } = useRole();

    const canViewMyWorkTimes = useMemo(() => {
        if (!appCompany || !role) {
            return false;
        }

        return appCompany.settings.employeeSettings.showWorkTimesForRoles.includes(role);
    }, [appCompany, role]);

    return { canViewMyWorkTimes };
}
